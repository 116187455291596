<template>
  <v-app>
    <v-content>
        <section id="hero">
          <v-row no-gutters>
            <video playsinline autoplay muted loop poster='@/assets/vjuglogo.png' height="100%" width="100%">
              <source :src='require("@/assets/vjugvideo.mp4")' type='video/mp4'>
            </video>
          </v-row>
        </section>

      <section id="hero2"
               class="hero2 darken-4">
        <br>
        <br>
        <v-row no-gutters>
            <v-theme-provider >
              <v-container fill-height>
                <v-row
                        align="center"
                        class="white--text mx-auto"
                        justify="center"
                >
                  <v-col
                          class="white--text text-center"
                          cols="12"
                          tag="h1"
                  >
                    <span
                            :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                            class="font-weight-light"
                    >
                      Virtual Java User Group North America
                    </span>
                      <br>
                      <br>
                      <span
                              :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                              class="font-weight-light"
                      >
                      Launching December 2020
                    </span>

                      <br>
                      <br>
                      <span
                              :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                              class="font-weight-light"
                      >
                          <h3>
                      <i>A joint effort by JUGs in Canada, USA, and Mexico (open to everyone of course!)</i>
                              </h3>
                    </span>




                  </v-col>
                    <br>
                    <br>

<!--                  <v-btn-->
<!--                          class="align-self-end"-->
<!--                          fab-->
<!--                          outlined-->
<!--                          @click="$vuetify.goTo('#about-me')"-->
<!--                  >-->
<!--                    <v-icon>mdi-chevron-double-down</v-icon>-->
<!--                  </v-btn>-->
                </v-row>
              </v-container>
            </v-theme-provider>
        </v-row>
      </section>


      <section
              id="features"
              class="participate lighten-3"
      >

        <v-container class="text-center">
            <br>
          <h3 class="display-1  mb-3">
            Join JUGs in North America every month to learn and network!
          </h3>
          <br>
          <h4 class="display-1  mb-3">
            Here's how your JUG can participate:
          </h4>

          <v-row>
            <v-col
                    v-for="({ icon, title, text }, i) in features"
                    :key="i"
                    cols="12"
                    md="4"
            >
              <v-card
                      class="py-12 px-4 cardbox"
                      height="350"
                      color="#9e5f0c"
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                            color="primary"
                            size="88"
                    >
                      <v-icon
                              large
                              v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                        class="justify-center font-weight-black text-uppercase cardbox"
                        v-text="title"

                ></v-card-title>

                <v-card-text
                        class="subtitle-1 white--text"
                        v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
                :height="$vuetify.breakpoint.smAndDown ? 800 : 600" width="100%"
                src="@/assets/vjuglogo.png"
        >
          <v-container fill-height>
<!--            <v-row class="mx-auto">-->
<!--              <v-col-->
<!--                      v-for="[value, title] of stats"-->
<!--                      :key="title"-->
<!--                      cols="12"-->
<!--                      md="3"-->
<!--              >-->
<!--                <div class="text-center">-->
<!--                  <div-->
<!--                          class="display-3 font-weight-black mb-4"-->
<!--                          v-text="value"-->
<!--                  ></div>-->

<!--                  <div-->
<!--                          class="title font-weight-regular text-uppercase"-->
<!--                          v-text="title"-->
<!--                  ></div>-->
<!--                </div>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-container>
        </v-parallax>
      </section>

<!--      <section id="blog">-->
<!--        <div class="py-12"></div>-->

<!--        <v-container>-->
<!--          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Blog</h2>-->

<!--          <v-responsive-->
<!--                  class="mx-auto mb-12"-->
<!--                  width="56"-->
<!--          >-->
<!--            <v-divider class="mb-1"></v-divider>-->

<!--            <v-divider></v-divider>-->
<!--          </v-responsive>-->

<!--          <v-row>-->
<!--            <v-col-->
<!--                    v-for="({ src, text, title }, i) in articles"-->
<!--                    :key="i"-->
<!--                    cols="12"-->
<!--                    md="4"-->
<!--            >-->
<!--              <v-img-->
<!--                      :src="src"-->
<!--                      class="mb-4"-->
<!--                      height="275"-->
<!--                      max-width="100%"-->
<!--              ></v-img>-->

<!--              <h3-->
<!--                      class="font-weight-black mb-4 text-uppercase"-->
<!--                      v-text="title"-->
<!--              ></h3>-->

<!--              <div-->
<!--                      class="title font-weight-light mb-5"-->
<!--                      v-text="text"-->
<!--              ></div>-->

<!--              <v-btn-->
<!--                      class="ml-n4 font-weight-black"-->
<!--                      text-->
<!--              >-->
<!--                Continue Reading-->
<!--              </v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-container>-->

<!--        <div class="py-12"></div>-->
<!--      </section>-->

      <v-sheet
              id="contact"
              color="#333333"
              dark
              tag="section"
              tile
      >
        <div class="py-12"></div>

        <v-container>

            <br>
            <br>
            <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contact</h2>

          <v-responsive
                  class="mx-auto mb-12"
                  width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <h2 class="display-2  mb-3  text-center">
                <a href="https://twitter.com/Virtual_JUG">
                    DM us on Twitter!
                </a>

                </h2>

          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-content>

    <v-footer
            class="justify-center"
            color="#292929"
            height="100"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — vjug.ca - vjug.us - vjug.mx
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// import VideoParallax from 'vuetify-video-parallax';
let ROOT_PATH = 'https://vjug.us';

export default {
  name: 'VirtualJUGNA',
  components: {
    // VideoParallax
  },
  data () {
    return {
      articles: [
        {
          src: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          title: 'Mobile first & Responsive',
          text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
        },
        {
          src: 'https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
          title: 'Think outside the box',
          text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
        },
        {
          src: 'https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80',
          title: 'Small changes, big difference',
          text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
        },
      ],
      features: [
          {
              icon: 'mdi-language-java',
              title: 'Host',
              text: 'Every month, a different JUG will host the VJUG! Get in touch with us and we\'ll get your regular JUG meeting setup to host the VJUG!' ,
          },
        {
          icon: 'mdi-handshake',
          title: 'Partner',
          text: 'Get on our mail list, and we\'ll send out details for the upcoming VJUG meetings so you can let your JUG community know.',
        },
        {
          icon: 'mdi-account-plus',
          title: 'Join the team',
          text: 'Interested in helping us organize VJUG-NA? Scroll down and click the link to DM us!',
        },
      ],
      stats: [
        ['24k', 'Github Stars'],
        ['330+', 'Releases'],
        ['1m', 'Downloads/mo'],
        ['5m', 'Total Downloads'],
      ],
        logo: ROOT_PATH + require('./assets/vjuglogo.png')
    }
  },
    metaInfo() {
        return {
            meta: [
                // Twitter Card
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: 'JUG LEADERS SUMMIT'},
                {name: 'twitter:description', content: 'JUG LEADERS SUMMIT 2021 virtual event.'},
                // image must be an absolute path
                {name: 'twitter:image', content: this.logo},
                // Facebook OpenGraph
                {property: 'og:title', content: 'JUG LEADERS SUMMIT'},
                {property: 'og:site_name', content: 'JUG LEADERS SUMMIT 2021 virtual event'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  this.logo},
                {property: 'og:description', content: 'JUG LEADERS SUMMIT 2021 virtual event.'}
            ]
        }
    }
};
</script>
<style>
    .hero2 {
        background-color: #eb9623;
    }

    .participate {
        background-color: #00669e;
        color: #fff;
    }

    .cardbox {
        color: #fff;
    }

</style>
